body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local('Poppins-Regular'),
    url('./fonts/Poppins-Regular.ttf') format('trueType');
}

@font-face {
  font-family: 'Poppins';
  font-style: light;
  font-weight: 300;
  src: local('Poppins-Light'),
    url('./fonts/Poppins-Light.ttf') format('trueType');
}

@font-face {
  font-family: 'Poppins';
  font-style: extralight;
  font-weight: 200;
  src: local('Poppins-ExtraLight'),
    url('./fonts/Poppins-ExtraLight.ttf') format('trueType');
}

@font-face {
  font-family: 'Poppins';
  font-style: bold;
  font-weight: 700;
  src: local('Poppins-Bold'), url('./fonts/Poppins-Bold.ttf') format('trueType');
}

@font-face {
  font-family: 'Poppins';
  font-style: bold;
  font-weight: 800;
  src: local('Poppins-ExtraBold'),
    url('./fonts/Poppins-ExtraBold.ttf') format('trueType');
}

@font-face {
  font-family: 'Poppins';
  font-style: bold;
  font-weight: 900;
  src: local('Poppins-Black'),
    url('./fonts/Poppins-Black.ttf') format('trueType');
}

@font-face {
  font-family: 'Poppins';
  font-style: bold;
  font-weight: 600;
  src: local('Poppins-SemiBold'),
    url('./fonts/Poppins-SemiBold.ttf') format('trueType');
}
